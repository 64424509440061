import { render, staticRenderFns } from "./AddFundPlan.vue?vue&type=template&id=304c92c0&scoped=true&"
import script from "./AddFundPlan.vue?vue&type=script&lang=js&"
export * from "./AddFundPlan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304c92c0",
  null
  
)

export default component.exports