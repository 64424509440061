<template>
  <el-card>
    <div>
      <el-form layout="inline">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="基金名称">
              <el-input placeholder style="width:60%" v-model="queryParam.fundName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <el-form-item label="基金编码">
              <el-input placeholder style="width:60%" v-model="queryParam.fundCode"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="6" :sm="12"></el-col>
          <el-col :md="18" :sm="24" style="width:70%">
            <el-button type="primary" @click="getFundPlanInfoList()">搜索</el-button>
            <el-button type="primary" @click="primaryRest()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 按钮区 -->
    <el-row :gutter="20" style="margin:9px 2px">
      <el-button type="success" icon="el-icon-add" size="min" @click="showAddFundPlan">新增投入计划</el-button>
      <el-button type="success" icon="el-icon-edit" size="min" @click="showEditFundPlan">投入基金</el-button>
      <!-- <el-button type="warning" icon="el-icon-edit" size="min">基金列表</el-button> -->
    </el-row>
    <!-- 列表区域 -->
    <el-table :data="fundPlanList" border stripe style="width: 100%" :row-class-name="tableRowClassName"> 
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio :label="scope.$index" v-model="radio" @change.native="getCurrentRow(scope.row)"></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="基金名称" prop="fundName" />
      <el-table-column label="投入月份" prop="recordMonth" />
      <el-table-column label="已经投入金额" prop="userCostMoney" />
      <el-table-column label="预计投入" prop="predictCostMoney" />
      <el-table-column label="上月收益" prop="lastMonthYield" />
      <el-table-column label="上月实际收入" prop="realityLastCost" />
      <el-table-column label="是否设置定投" prop="isFundAuto" />
      <el-table-column label="备注" prop="remark" />
    </el-table>
    <AddFundPlan ref="addFundPlan" />
    <EditFundPlan ref="editFundPlan" />
  </el-card>
</template>

<script>
import AddFundPlan from "./modules/AddFundPlan";
import EditFundPlan from "./modules/EditFundPlan";
import { list } from "@/api/capital/fundPlan";

export default {
  name: "fundPlan",
  components: {
    AddFundPlan,
    EditFundPlan
  },
  data() {
    return {
      fundPlanList: [],
      radio: "",
      queryParam: {
        fundName: "",
        fundCode: "",
        fundStatus: "",
        updateStatus: ""
      }
    };
  },
  mounted() {
    this.getFundPlanInfoList();
  },

  created() {
    this.getFundPlanInfoList();
  },
  methods: {
    getFundPlanInfoList() {
      list(this.queryParam).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        this.fundPlanList = res.result;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.templateSelection = row;
    },
    primaryRest() {
      this.queryParam.fundName = "";
      this.queryParam.fundCode = "";
      this.queryParam.fundStatus = "";
      this.queryParam.updateStatus = "";
      this.getFundList();
    },
    showAddFundPlan() {
      this.$refs.addFundPlan.showAddFundPlans();
    },
    showEditFundPlan() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      this.$refs.editFundPlan.showEditFundPlan(this.id);
    },
   tableRowClassName({row, rowIndex}) {
      if(row.userCostMoney>row.predictCostMoney){
        return 'warning-row';
      }
      
      }
  }
};
//样式有问题
</script>
<style  >
  .el-table .warning-row {
    background: rgb(230, 111, 42) !important;
  }
 
  .el-table .success-row {
    background: #f0f9eb;
  }
</style>