<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="AddFundPlanFrom" label-width="100px">
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="基金编码" width="120px">
            <el-select v-model="CodeList" placeholder="请选择" @change="optButton">
              <el-option
                v-for="item in optionCode"
                :key="item.id"
                :label="item.fundName"
                :value="{label:item.id,value:item.fundName,type:item.fundType,
                fundIncomeMoney:item.fundIncomeMoney,
                fundSupMoney:item.fundSupMoney,
                fundPreSumMoney:item.fundPreSumMoney,
                fundPutMoney:item.fundPutMoney}"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="基金名称" width="120px">
            <el-input width="20px" v-model="AddFundPlanFrom.fundName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="基金类型" width="120px">
            <el-input width="20px" v-model="AddFundPlanFrom.fundType" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="实际收益金额" width="120px">
            <el-input width="20px" v-model="AddFundPlanFrom.fundIncomeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="剩余投入金额" width="120px">
            <el-input width="20px" v-model="AddFundPlanFrom.fundSupMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="预计实际收益金额" width="120px">
            <el-input width="20px" v-model="AddFundPlanFrom.fundPreSumMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="本次更新收益金额" width="120px">
            <el-input-number
              width="20px"
              v-model="AddFundPlanFrom.monthYield"
              @change="changeMoney"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="更新实际收益金额" width="120px">
            <el-input width="20px" v-model="AddFundPlanFrom.newFundPreSumMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="当月预计投入" width="120px">
            <el-input-number width="20px" v-model="AddFundPlanFrom.predictCostMoney" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="累积已经投入金额" width="120px">
            <el-input-number width="20px" v-model="AddFundPlanFrom.userCostMoney"/>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="上月的收益" width="120px">
            <el-input width="20px" v-model="AddFundPlanFrom.lastMonthYield" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="updeteButton()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { selectFundList } from "@/api/capital/fund";
import { addFundPlanMoney } from "@/api/capital/fundPlan";
export default {
  name: "AddFundPlan",
  data() {
    return {
      dialogVisible: false,
      AddFundPlanFrom: {
        userCostMoney:0
      },
      CodeList: {
        label: null,
        value: null
      },
      optionCode: []
    };
  },
  methods: {
    //打开弹窗
    showAddFundPlans() {
      console.log("添加1212");
      this.dialogVisible = true;
      this.selectFundListButton();
    },
    selectFundListButton() {
      selectFundList(99).then(res => {
        console.log(res);
        this.optionCode = res.result;
      });
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    changeMoney() {
      this.AddFundPlanFrom.newFundPreSumMoney =
        this.AddFundPlanFrom.monthYield + this.AddFundPlanFrom.fundSupMoney;
      //上月收益
      this.AddFundPlanFrom.lastMonthYield =
        this.AddFundPlanFrom.monthYield - this.AddFundPlanFrom.fundIncomeMoney;
    },
    updeteButton() {
    if(this.AddFundPlanFrom.userCostMoney>this.AddFundPlanFrom.fundSupMoney){
      return this.$message.error("累积投入金额超过剩余投入金额");
    }
      addFundPlanMoney(this.AddFundPlanFrom).then(res=>{
        this.dialogVisible=false;
        this.$parent.getFundPlanInfoList
      })
    },
    optButton() {
      console.log(this.CodeList);
      this.AddFundPlanFrom.fundName = this.CodeList.value;
      this.AddFundPlanFrom.fundType = this.CodeList.type;
      this.AddFundPlanFrom.fundIncomeMoney = this.CodeList.fundIncomeMoney;
      this.AddFundPlanFrom.fundSupMoney = this.CodeList.fundSupMoney;
      this.AddFundPlanFrom.fundPreSumMoney = this.CodeList.fundPreSumMoney;
      this.AddFundPlanFrom.fundId=this.CodeList.label;
      this.AddFundPlanFrom.fundPutMoney=this.CodeList.fundPutMoney;
    }
  }
};
</script>
<style lang="less" scoped>
</style> 